removed.controller('RemovedOldDataController', ['$scope', 'RemovedOldDataResource',
    function ($scope, RemovedOldDataResource) {
		$scope.types = [
			{'id': '', 'name': 'Wszystkie'},
			{'id': 'devices', 'name': 'Urządzenia'},
			{'id': 'users', 'name': 'Użytkownicy'},
			{'id': 'companies', 'name': 'Firmy'},
			{'id': 'messages', 'name': 'Wiadomości'},
		];
		$scope.data = {
			type: '',
			dir: '',
		};
		$scope.dirs = [];
        $scope.files = [];
        $scope.error = '';
        $scope.reports = [];
		$scope.reports_error = '';

        $scope.getFiles = getFiles;
        $scope.getReports = getReports;

        getDirs();
        getFiles();
        getReports();

        function getDirs() {
        	RemovedOldDataResource.get({action: 'dirs'}, function (response) {
        		$scope.dirs = [];
                if (response.success) {
                    $scope.dirs = response.data;
                    if ($scope.dirs.length) {
                    	$scope.data.dir = $scope.dirs[0];
                    	getFiles();
                        getReports();
                    }
                }
            });
        }

        function getFiles(dir, type) {
        	if (typeof dir === 'undefined') {
        		dir = $scope.data.dir;
        	}
        	if (typeof type === 'undefined') {
        		type = $scope.data.type;
        	}

        	RemovedOldDataResource.get({action: 'files', dir: dir, type: type}, function (response) {
        		$scope.files = [];
        		$scope.error = '';
                if (response.success) {
                    $scope.files = response.data;
                } else {
                	$scope.error = response.error;
                }
            });
        }

        function getReports(year) {
        	if (typeof year === 'undefined') {
        		year = $scope.data.dir;
        	}

        	RemovedOldDataResource.get({action: 'reports', year: year}, function (response) {
        		$scope.reports = [];
        		$scope.reports_error = '';
                if (response.success) {
                    $scope.reports = response.data;
                } else {
                	$scope.reports_error = response.error;
                }
            });
        }
    }
]);
